/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

// export const shouldUpdateScroll = ({
//                                 routerProps: { location },
//                                 getSavedScrollPosition,
//                               }) => {
//   const { pathname } = location
//   // list of routes for the scroll-to-top-hook`
//   const scrollToTopRoutes = [`/`, `/about`, `/explore-our-projects/our-projects`, `/press`, `/contact`,`/explore-our-projects/*`, `/gallery/*`, `/interiors-exteriors/areas/*`]
//   // if the new route is part of the list above, scroll to top (0, 0)
//   if (scrollToTopRoutes.indexOf(pathname) !== -1) {
//     document.querySelector("#gatsby-focus-wrapper").scroll(0,0)
//   }
//
//   return false
// }



// You can delete this file if you're not using it
export const wrapRootElement = ({ element }) => {
  return (
    <ParallaxProvider>{element}</ParallaxProvider>
  );
}
