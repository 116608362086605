exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-areas-js": () => import("./../../../src/templates/areas.js" /* webpackChunkName: "component---src-templates-areas-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-portfolios-js": () => import("./../../../src/templates/portfolios.js" /* webpackChunkName: "component---src-templates-portfolios-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */),
  "component---src-templates-single-gallery-js": () => import("./../../../src/templates/single-gallery.js" /* webpackChunkName: "component---src-templates-single-gallery-js" */),
  "component---src-templates-single-portfolio-js": () => import("./../../../src/templates/single-portfolio.js" /* webpackChunkName: "component---src-templates-single-portfolio-js" */),
  "component---src-templates-single-press-js": () => import("./../../../src/templates/single-press.js" /* webpackChunkName: "component---src-templates-single-press-js" */)
}

